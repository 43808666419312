/* font imports */

@font-face {
    font-family: 'Larsseit-Medium';
    src: url('../public/fonts/Larsseit-Medium/Larsseit-Medium.eot'); /* IE9 Compat Modes */
    src: url('../public/fonts/Larsseit-Medium/Larsseit-Medium.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../public/fonts/Larsseit-Medium/Larsseit-Medium.woff')
            format('woff'),
        /* Pretty Modern Browsers */
            url('../public/fonts/Larsseit-Medium/Larsseit-Medium.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../public/fonts/Larsseit-Medium/Larsseit-Medium.svg')
            format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Larsseit-Extrabold';
    src: url('../public/fonts/Larsseit-ExtraBold/Larsseit-ExtraBold.eot'); /* IE9 Compat Modes */
    src: url('../public/fonts/Larsseit-ExtraBold/Larsseit-ExtraBold.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../public/fonts/Larsseit-ExtraBold/Larsseit-ExtraBold.woff')
            format('woff'),
        /* Pretty Modern Browsers */
            url('../public/fonts/Larsseit-ExtraBold/Larsseit-ExtraBold.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../public/fonts/Larsseit-ExtraBold/Larsseit-ExtraBold.svg')
            format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Larsseit-ExtraboldItalic';
    src: url('../public/fonts/Larsseit-ExtraBoldItalic/Larsseit-ExtraBoldItalic.eot'); /* IE9 Compat Modes */
    src: url('../public/fonts/Larsseit-ExtraBoldItalic/Larsseit-ExtraBoldItalic.woff2')
            format('woff2'),
        /* Super Modern Browsers */
            url('../public/fonts/Larsseit-ExtraBoldItalic/Larsseit-ExtraBoldItalic.woff')
            format('woff'),
        /* Pretty Modern Browsers */
            url('../public/fonts/Larsseit-ExtraBoldItalic/Larsseit-ExtraBoldItalic.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('../public/fonts/Larsseit-ExtraBoldItalic/Larsseit-ExtraBoldItalic.svg')
            format('svg'); /* Legacy iOS */
}

/* resets */
/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

ul {
    padding: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.hero-image {
    background-image: url('./assets/munich.png');
    background-size: cover;
    position: fixed;
    bottom: 0;
    width: 100%;
    min-height: 700px;
    background-position: center bottom;
    background-repeat: no-repeat;
    z-index: -50;
}
